import { IGetImage, IMG_CATEGORY, IShipyardImage } from "src/types";
import { getDownloadURL, ref } from "firebase/storage";
import * as db from "../useDb";
import storage from "./storage";
import getShiptypeImages from "./shiptypes";
export { ShipyardUrls, BannerUrls } from "./urlMaps";
export { listSkinDir } from "./getSkins";
export { getShiptypeImages }; // init
type IStoredImage = IShipyardImage;

const specialDownload = (url: string, props: IGetImage) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.response === null) return;
      var MyBlob = new Blob([xhr.response], { type: "text/plain" });
      if (MyBlob instanceof Blob) {
        resolve(xhr.response);
        db.storeImg(props, MyBlob);
      }
    };
    //@ts-ignore
    xhr.open("GET", url);
    xhr.send();
  });
};
const addImageSuffix = (props: IGetImage) => {
  switch (props.imageCategory) {
    case IMG_CATEGORY.shipyard:
      return "thumbnail";
    case IMG_CATEGORY.banners:
      return "banner";
    case IMG_CATEGORY.icons:
      return "icon";
    default:
      return props.shipName;
  }
};
//**
//  * DONT NEED TO PRE-LOAD ANYTHING BUT BANNERS
//  */
// const storage = getStorage(app);
const getImageBlob = async (props: IGetImage): Promise<any> => {
  const shipName = props.shipName
    ? props.shipName.replace(/ /g, "_")
    : undefined;
  const { imageCategory } = props;

  const imgFromnDb: Blob | undefined = await db.getImgFromDb({
    id: props.id,
    imageCategory,
  });
  if (imgFromnDb && imgFromnDb) {
    return imgFromnDb; // Img was stored locally. Done.
  }
  const prefixUrl = `skins/${props.id}`;
  // Img is not stored locally. We must now get a Download url
  const properlyNamedImage = addImageSuffix({ ...props, shipName });
  const dlUrl = await getDownloadURL(
    ref(storage, `${prefixUrl}/${properlyNamedImage}.webp`)
  );
  if (dlUrl) {
    return specialDownload(dlUrl, { imageCategory, id: props.id });
  }
};

export default getImageBlob;
