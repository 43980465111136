import styled from "@emotion/styled";
import ResizedText from "src/components/common/ResizedText";
import { DEVICE_SIZE_STYLES } from "src/constants";
import { NationUrls } from "src/hooks/useImage/urlMaps";
import SubSumImage from "./SubSumImage";

const StyledNationality = styled.img`
  max-height: 5rem;
  height: auto;
  width: 2rem;
  object-fit: contain;
  margin-right: 10;
  @media ${DEVICE_SIZE_STYLES.laptopL} {
    // height: 4rem;
    // width: 4rem;
  }
`;

const Nationality = ({ nationality }: any) => {
  if (!nationality) {
    return null;
  }
  return (
    <div
      style={{ display: "flex", alignItems: "center", alignContent: "center" }}>
      <SubSumImage type={"nationality"} src={NationUrls.get(nationality)} />
      <ResizedText className="subtitle text-shadow">{nationality}</ResizedText>
    </div>
  );
};

export default Nationality;
