import { Ship } from "src/types";
type ShipDrop = {
  dropNote: string;
  dropLocations: string[];
  constructionCategories: Record<string, boolean>;
  constructionNote: string;
};
type Availability =
  | "Drop Only"
  | "Drop/Construction"
  | "Construction"
  | "Drops during Event"
  | "Event Construction"
  | "Research"
  | "Special (see note or wiki)"
  | "Munitions Shop"
  | "Exchange during Event";

const toShipDrop = (construction: any, obtainedFrom: any): ShipDrop => {
  const constCats = construction.availableIn;

  for (const category in constCats) {
    if (constCats[category] === false) {
      delete constCats[category];
    }
  }
  return {
    dropNote: obtainedFrom.obtainedFrom,
    dropLocations: obtainedFrom.fromMaps,
    constructionCategories:
      Object.keys(constCats).length === 0 ? undefined : constCats,
    constructionNote: construction.constructionTime,
  };
};
// TEST WITH YUUDACHI, brooklyn,
export const determineIfAvailable = (
  construction: any,
  obtainedFrom: any,
  rarity: Ship.Rarity
): Availability => {
  const data = toShipDrop(construction, obtainedFrom);

  if (data.constructionCategories === undefined) {
    // Can't/couldn't build
    if (data.dropLocations.length === 0) {
      // Doesn't/didn't drop
      if (rarity === "Priority" || rarity === "Decisive") {
        // Ship is Priority
        return "Research";
      } // Ship MIGHT have a constructionNote
      return "Special (see note or wiki)";
    }
    // They drop, but not construct
    if (data.dropNote !== undefined) {
      if (data.dropLocations.length === 0) {
        return "Drops during Event";
      }
      // ??
    }
    if (data.dropLocations.length > 0) return "Drop Only";
  }
  if (data.constructionCategories.limited !== undefined) {
    return "Event Construction";
  } else if (data.dropNote !== undefined) {
    if (
      data.constructionCategories.exchange &&
      Object.keys(data.constructionCategories).length === 1
    ) {
      // Only available in Munitions
      return "Munitions Shop";
    }
    if (data.dropNote !== "Building#Exchange") {
      // Ship is available in exhange AND other constructions
      return "Exchange during Event";
    }
    if (data.dropNote === "Building#Exchange") {
      return "Construction";
    }
  } else if (data.dropLocations === undefined) {
    return "Construction";
  }
  return "Drop/Construction"; // Has locations AND categories
};
