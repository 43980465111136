import React from "react";
import useBoatStore, { BoatStore, shallow } from "src/hooks/useBoatContext";
import { Table } from "./Table";
const selector = (state: BoatStore): [string, any] => [
  state.wikiUrl ?? "",
  state.misc,
];
const Links = () => {
  const misc = useBoatStore(selector, shallow);
  if (misc[1] === null) {
    return null;
  }
  return (
    <div style={{ flexShrink: 1, overflow: 'hidden' }}>
      {/* <p>Wiki Url</p>
      <a href={links ?? ""} rel="external">
        Link
      </a> */}
      <Table wikiUrl={misc[0]} artist={misc[1].artist} voice={misc[1].voice} />
    </div>
  );
};

export default Links;
