import { css } from "@emotion/css";
import React from "react";
import type { Ship } from "src/types";
import icons from "src/components/common/StatIcons";

const ImageCss = css`
  max-width: 1.75rem;
  height: auto;
`;
export interface TableProps extends Required<Ship.Misc> {
  wikiUrl: string;
}

const STAT_KEYS = [
  "health",
  "firepower",
  "torpedo",
  "accuracy",
  "antiair",
  "antisubmarineWarfare",
  "aviation",
  "evasion",
  "luck",
  "oilConsumption",
  "reload",
  "speed",
  "armor",
];
const TableHeader = React.memo(
  ({ start, end }: { start: number; end: number }) => (
    <thead>
      <tr>
        {STAT_KEYS.slice(start, end).map((key) => (
          <th key={`stat-${key}`}>
            <img
              className={ImageCss}
              alt={key}
              src={`data:image/png;base64, ${icons[key]}`}
            />
          </th>
        ))}
      </tr>
    </thead>
  )
);
const Table = ({ selectedStats = {} }: any) => {
  return (
    <table className="table" style={{background: 'transparent', tableLayout: "fixed", width: "350px" }}>
      <TableHeader start={0} end={6} />
      <tbody>
        <tr>
          {STAT_KEYS.slice(0, 6).map((s) => (
            <td key={`stat-icon${s}`}>{selectedStats[s] ?? 0}</td>
          ))}
        </tr>
      </tbody>
      <TableHeader start={7} end={13} />
      <tbody>
        <tr>
          {STAT_KEYS.slice(7, 13).map((s) => (
            <td key={`stat-stat${s}`}>{selectedStats[s] ?? 0}</td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
