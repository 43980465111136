import { lazy, Suspense, useEffect, useState } from "react";
import Dropdown from "react-dropdown";
import useBoatStore, { shallow } from "src/hooks/useBoatContext";
import styled from "@emotion/styled";
import "./stats.css";
import { Ship } from "src/types";
import Loader from "src/components/common/Loader";
import StatTable from "./StatTable";
import Tabs from "./Tabs";
type ArrowProps = {
  open: boolean;
};
const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Arrow = styled.span<ArrowProps>`
  border: solid #999;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 10px;
  ${(props) =>
    props.open
      ? ` top: 14px;
     transform: rotate(-135deg);
     -webkit-transform: rotate(-135deg);`
      : ` top: 10px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);`}
`;

const statsSelector = (state: any) => ({
  hasRetrofit: state.hasRetrofit,
  stats: state.stats,
  setSelectedLevel: state.setSelectedLevel,
  hexagon: state.hexagon,
});

type StatDropdownOptions = { value: keyof Ship.ShipStats; label: any };
const dropdownOptions: StatDropdownOptions[] = [
  { value: "baseStats", label: "Level 0" },
  { value: "level100", label: "Level 100" },
  { value: "level120", label: "Level 120" },
  { value: "level125", label: "Level 125" },
  { value: "level100Retrofit", label: "Retrofit 100" },
  { value: "level120Retrofit", label: "Retrofit 120" },
  { value: "level125Retrofit", label: "Retrofit 125" },
];

const Stats = () => {
  const { stats, setSelectedLevel, hasRetrofit } = useBoatStore(
    statsSelector,
    shallow
  );
  let options = hasRetrofit ? dropdownOptions : dropdownOptions.slice(0, 4);
  const [selectedOption, setSelectedOption] = useState<any>(dropdownOptions[0]);
  const [selectedStats, setSelectedStats] = useState<any>();
  const handleChange = (o: any) => {
    setSelectedOption(o);
    setSelectedLevel(o.value);
  };

  useEffect(() => {
    if (stats === null) {
      // Nullify stat obj
      // dont nullify store so next ship get this lebel
      // setSelectedLevel(dropdownOptions[0].value);
      setSelectedStats(undefined);
      return;
    }
    if (stats[selectedOption.value] === undefined) {
      // This ship doesn'thave retrofit levels.
      setSelectedOption(options[0]);
      setSelectedLevel(options[0].value);
    }
    setSelectedStats(stats[selectedOption.value]);
  }, [stats, selectedOption, setSelectedLevel]);
  return (
    <StyledStatsContainer>
      <div className="dropdown-parent">
        <Dropdown
          arrowClosed={<Arrow open={false} />}
          arrowOpen={<Arrow open={true} />}
          options={options}
          onChange={handleChange}
          value={selectedOption}
        />
      </div>

      <StatTable selectedStats={selectedStats} />
    </StyledStatsContainer>
  );
};
const StatsContainer = () => {
  return (
    <div className="bg-blue-opaq box">
      <Tabs />
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}>
        <Stats />
      </div>
    </div>
  );
};

export { StatsContainer };
