import create, { GetState, SetState } from "zustand";
import shallow from "zustand/shallow";
import { devtools } from "zustand/middleware";
import type { BoatStore, Ship } from "src/types";
import { log } from "./useAnal";
import fetchDetail from "./fetchDetail";
export { BoatStore };
export type SearchSlice = {
  results: Ship.Summary[];
  setResults: (r: any[]) => void;
};

export type DetailStore = {
  selectedLevel: SelectedLevel;
  // stats: SelectedStat | null;
  setSelectedLevel: (level: keyof Ship.ShipStats) => void;
  setRetrofit: (bool: boolean) => void;
  retrofitSelected: boolean;
};

type SelectedLevel = keyof Ship.ShipStats;

export type StoreState = SearchSlice & BoatStore & DetailStore;
type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
) => T;

const initialBoatState = {
  wikiUrl: null,
  name: null,
  id: null,
  stats: null,
  skills: null,
  nationality: null,
  rarity: null,
  hasRetrofit: null,
  stars: null,
  construction: null,
  obtainedFrom: null,
  type: null,
  links: null,
  hexagon: null,
  skins: null,
  misc: null,
};

const searchResultSlice: StoreSlice<SearchSlice> = (set, _) => ({
  results: [],
  setResults: (r: Ship.Summary[]) => {
    set({ results: [...r] });
    if (r.length === 0) {
      deselectBoat();
    }
  },
});
const boatSlice: StoreSlice<BoatStore> = (set, get) => ({
  ...initialBoatState,
  selectBoat: (boat: Ship.Summary) => {
    log();
    set({
      id: boat.id,
      name: boat.name,
      nationality: boat.nationality,
      rarity: boat.rarity,
      hasRetrofit: boat.hasRetrofit,
      type: boat.type,
    });
    const urlParams = new URLSearchParams();
    urlParams.set("ship", boat.name);
    window.history.replaceState({}, boat.name, `?${urlParams.toString()}`);
    document.title = boat.name;
    fetchDetail(boat.id).then((detail) => {
      //@ts-ignore
      return set((state) => {
        state.setSelectedLevel(state.selectedLevel);
        console.log(detail);
        if (
          detail.obtainedFrom &&
          detail.obtainedFrom.obtainedFrom === undefined
        ) {
          detail.obtainedFrom.obtainedFrom = " ";
        }
        return detail;
        // return {
        //   stars: detail.stars,
        //   stats: detail.stats,
        //   skills: detail.skills,
        //   skins: detail.skins,
        //   wikiUrl: detail.wikiUrl,
        //   misc: {
        //     artist: detail.misc.artist,
        //     voice: detail.misc.voice,
        //   },
        //   construction: detail.construction,
        //   obtainedFrom: detail.obtainedFrom,
        //   hexagon: detail.hexagon,
        // };
      });
    });
  },
  deselectBoat: () => {
    //@ts-ignore
    set({ ...initialBoatState });
  },
});
const useDetailStore: StoreSlice<DetailStore> = (set, get) => ({
  selectedLevel: "baseStats",
  setSelectedLevel: (level) => {
    set((prev) => {
      const newState = { ...prev };
      newState.selectedLevel = level;
      switch (level) {
        case "level100Retrofit":
        case "level120Retrofit":
        case "level125Retrofit":
          if (newState.hasRetrofit) {
            // allow retrofit state
            newState.retrofitSelected = true;
          }
          break;
        default:
          newState.retrofitSelected = false;
      }

      return newState;
    });
  },
  setRetrofit: (bool: boolean) => set({ retrofitSelected: bool }),
  retrofitSelected: false,
});

const useBoatStore = create<StoreState>(
  devtools((set, get) => ({
    ...searchResultSlice(set, get),
    ...boatSlice(set, get),
    ...useDetailStore(set, get),
  }))
);
const deselectBoat = () => {
  window.history.replaceState({}, "Weaboats", `?`);
  //@ts-ignore
  useBoatStore.setState({ ...initialBoatState });
};
const nameSelector = (state: BoatStore) => state.name;
export { shallow, nameSelector, useDetailStore };
export default useBoatStore;
