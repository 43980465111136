//@ts-nocheck
import { Routes, Route } from "react-router-dom";
import { Fragment, Suspense, lazy } from "react";

import MainApp from "./MainApp";
import Loader from "src/components/common/Loader";

const Privacy = lazy(() => import("./Privacy"));
const Acknowledgements = lazy(() => import("./Acknowledgements"));
const Disclosure = lazy(() => import("./Disclosure"));
const Contact = lazy(() => import("./Contact"));
const MainComponent = () => {
  // const location = useLocation();
  // const transitionds = useTransition(location, {
  //   from: { opacity: 0, transform: "translateX(50px)", position: "absolute" },
  //   enter: { opacity: 1, transform: "translateX(0px)", position: "relative" },
  //   leave: {
  //     opacity: 0,
  //     transform: "translateX(-50px)",
  //     position: "absolute",
  //     immediate: true,
  //   },
  //   unique: true,
  //   keys: location.pathname,
  // });
  return (
    <Fragment>
      {/* {transitions((props, item) => ( */}
      {/* <a.div style={props}> */}
      <Routes>
        <Route
          exact
          path="/acknowledgements"
          element={
            <div className="section">
              <Suspense fallback={<Loader />}>
                <Disclosure />
                <Acknowledgements />
              </Suspense>
            </div>
          }
        />
        <Route
          exact
          path="/privacy"
          element={
            <div className="section">
              <Suspense fallback={<Loader />}>
                <Privacy></Privacy>
              </Suspense>
            </div>
          }></Route>
        <Route
          exact
          path="/contact"
          element={
            <div className="section">
              <Suspense fallback={<Loader />}>
                <Contact />
              </Suspense>
            </div>
          }></Route>
        <Route exact path="/" element={<MainApp />}></Route>
        {/* <Route exact path="/app">
          <Intro />
        </Route> */}
      </Routes>
      {/* </a.div> */}
      {/* ))} */}
    </Fragment>
  );
};

export default MainComponent;
