import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { ShipImage } from "./ShipImage";
import { StatsContainer } from "./Stats";
import "./detail.scss";
import Skills from "./Skills";
import ShipName from "./ShipName";
import { thecolor, updateRarity } from "../RarityBackground";
import useBoatStore, { DetailStore } from "src/hooks/useBoatContext";
import { BoatStore, Ship } from "src/types";
import { useSpring, animated as a, easings } from "react-spring";
import Nationality from "./SubSummary";
import Construction from "./Construction";

const selector = (state: BoatStore & DetailStore) =>
  state.hasRetrofit && state.retrofitSelected
    ? updateRarity(state.rarity)
    : state.rarity;
const StyledTopDiv = styled.div`
  grid-template-areas: "availability detail-image";
`;
const DetailComponent = styled.div`
  padding: 1rem;
  position: relative;
  background-size: cover;
  border-radius: 16px;
  height: inherit;
`;

const ADetailComponent = a(DetailComponent);
const TopDetail = () => {
  return (
    <StyledTopDiv className="top-div">
      <div className="availability">
        <ShipName />
        <Construction />
      </div>
      <div className="detail-image right-side">
        <ShipImage />
        <Nationality />
      </div>
    </StyledTopDiv>
  );
};
const Detail = () => {
  return (
    <DetailBackground>
      <div className="shipname">
        <TopDetail />
      </div>

      <div className="skills">
        <Skills />
      </div>
      <div className="stats">
        <StatsContainer />
      </div>
    </DetailBackground>
  );
};
const DetailBackground = ({ children }: any) => {
  const rarity = useBoatStore(selector);
  const spring = useSpring({
    background: `linear-gradient(130deg, ${thecolor(rarity)})`,
    config: { duration: 200, easings: easings.easeInBack },
  });
  return (
    <ADetailComponent
      style={{ background: spring.background }}
      className="detail-container">
      {children}
    </ADetailComponent>
  );
};
export default Detail;
