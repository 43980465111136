import { Ship } from "src/types";
import create, { GetState, SetState } from "zustand";
import { devtools } from "zustand/middleware";
type FilterParams = {
  nationality: Ship.Nationality | null;
  type: Ship.Type | null;
  isOpen: boolean;
};
export type FilterSlice = {
  nationality: Ship.Nationality | null;
  type: Ship.Type | null;
  isOpen: boolean;
  searchString: string;
  setSearchString: (s: string) => void;
  setOpen: (b: boolean) => void;
  setFilter: (f: any) => void;
  resetFilter: () => void;
};
type StoreState = FilterSlice;
type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
) => T;

const FilterStoreSlice: StoreSlice<FilterSlice> = (set, get) => ({
  searchString: "",
  nationality: null,
  type: null,
  isOpen: false,
  setSearchString: (s: string) => set({ searchString: s }),
  setOpen: (to: boolean) => set({ isOpen: to }),
  setFilter: (f) => set({ ...f }),
  resetFilter: () => set({ nationality: null, type: null }),
});

const useFilter = create<StoreState>(
  devtools((set, get) => ({
    ...FilterStoreSlice(set, get),
  }))
);

export default useFilter;
