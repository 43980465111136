// import { Ship } from "src/types";
import create, { GetState, SetState } from "zustand";
import { devtools } from "zustand/middleware";

export type SkinStore = {
  skinViewerOpen: boolean;
  setSkinViewerOpen: (b: boolean) => void;
};
type StoreState = SkinStore;
type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
) => T;

const FilterStoreSlice: StoreSlice<SkinStore> = (set) => ({
  skinViewerOpen: false,
  setSkinViewerOpen: (b: boolean) => set({ skinViewerOpen: b }),
});

const useSkinViewer = create<StoreState>(
  devtools((set, get) => ({
    ...FilterStoreSlice(set, get),
  }))
);

export default useSkinViewer;
