import React, { useEffect, useState } from "react";
import { animated as a, useSpring, config } from "react-spring";
import ggg from "src/hooks/useImage";
import useBoatStore, { DetailStore, shallow } from "src/hooks/useBoatContext";
import { Image } from "src/components/common";
import { BoatStore, IMG_CATEGORY } from "src/types";
import ImgBgComponent from "./ImgBgComponent";

const shipImageSelector = (state: BoatStore & DetailStore) =>
  state.retrofitSelected && state.hasRetrofit && state.id
    ? (Number.parseInt(state.id) + 3000).toString()
    : state.id;

const ObjectUrls = new Map();

const ShipImage = () => {
  const id = useBoatStore(shipImageSelector, shallow);
  const [currentUrl, setCurrentUrl] = useState<string>();

  const [styles, api] = useSpring(() => ({
    config: config.stiff,
    opacity: 0,
    x: 13,
  }));
  const handleImageLoad = React.useCallback(() => {
    api.start({ opacity: 1, x: 0 });
  }, [api]);
  const handleImageStart = React.useCallback(() => {
    api.start({
      opacity: 0.3,
      x: 5,
    });
  }, [api]);
  const handleDeselect = React.useCallback(
    () =>
      api.start({
        x: 13,
        opacity: 0,
        onRest: () => setCurrentUrl(undefined),
      }),
    [api]
  );

  useEffect(() => {
    if (id === null) {
      handleDeselect();
      return;
    }
    if (id && id.length > 0) {
      if (ObjectUrls.has(id)) {
        return setCurrentUrl(ObjectUrls.get(id));
      }
      handleImageStart();
      ggg({ imageCategory: IMG_CATEGORY.shipyard, id })
        .then((b) => {
          const url = URL.createObjectURL(b);
          ObjectUrls.set(id, url);
          setCurrentUrl(url);
        })
        .catch((err) => {
          alert("I don't have this shipyard image.");
          handleDeselect();
        });
    }
    // return objurl && URL.revokeObjectURL(objurl)
  }, [id, handleDeselect, handleImageStart]);
  return (
    <ImgBgComponent>
      <a.div style={{ opacity: styles.opacity, x: styles.x }}>
        {currentUrl && (
          <Image
            src={currentUrl}
            style={{
              display: "block",
              width: "100%",
              height: "auto",
              objectFit: "contain",
            }}
            onLoad={handleImageLoad}
          />
        )}
      </a.div>
    </ImgBgComponent>
  );
};

export { ShipImage };
