/* eslint-disable react/jsx-filename-extension */
import React, { lazy, Suspense, useEffect, useState } from "react";
import useBoatStore from "src/hooks/useBoatContext";
import { BoatStore, Ship } from "src/types";
import TextExtraction, {
  ExtractedText,
} from "src/components/common/TextExtraction";
import styled from "@emotion/styled";
import SkillIcons from "./SkillIcons";
import Loader from "src/components/common/Loader";
import { HasRetrofit } from "../HasRetrofit";
const Hexagon = lazy(() => import("./Hexagon"));

interface StyledSkill extends Ship.Skill {
  styledDesc: string[];
}
const StyledSkillsBody = styled.div`
  padding: 1.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  box-sizing: border-box;
  width: calc(100% + 20px);
`;

const StyledSkillsWrapper = styled.div`
  min-height: 13rem; // Min-hieght as long as hexagon is sibling.
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
`;
const extractText = (skills: Ship.Skill[]) => {
  return skills.map((skill) => {
    const x = new TextExtraction(skill.description, [
      { pattern: /\(([^).]+)\)/, className: "text-yellow" },
    ]).parse();
    return { ...skill, styledDesc: x } as StyledSkill;
  });
};
const skillSelector = (state: BoatStore) => state.skills;

const Skills = () => {
  const skills = useBoatStore(skillSelector);
  const [styledSkills, setStyledSkills] = useState<StyledSkill[]>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (skills && skills[0]) {
      setSelectedIndex(0); // Diferent ship selected
      const skillsArr = extractText(skills);
      setStyledSkills([...skillsArr]);
      return;
    }
    setStyledSkills(undefined);
  }, [skills]);

  return (
    <>
      <div className="top-div">
        <SkillIcons
          skills={styledSkills}
          currentSelected={selectedIndex}
          onSelect={setSelectedIndex}
        />
        <HasRetrofit />
      </div>
      <div className="top-div">
        <StyledSkillsWrapper className="bg-dark-blue-opaq">
          {styledSkills && (
            <StyledSkillsBody>
              <p className="title is-4 text-yellow">
                {styledSkills[selectedIndex]?.names.en}
              </p>
              <ExtractedText text={styledSkills[selectedIndex].styledDesc} />
            </StyledSkillsBody>
          )}
        </StyledSkillsWrapper>
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }>
          <Hexagon />
        </Suspense>
      </div>
    </>
  );
};

export default Skills;
