import React from "react";
import styled from "@emotion/styled";
import useBoatStore, { BoatStore } from "src/hooks/useBoatContext";
import { Ship } from "src/types";
import Nationality from "./Nationality";
import ShipType from "./ShipType";
import { DEVICE_SIZE_STYLES } from "src/constants";
// import { DEVICE_SIZE_STYLES } from "src/constants";
type SubSumSelector = [string | null, string | null, Ship.Type | null];
const StyledSub = styled.div`
  padding-top: 0.5rem;
  flex-grow: 1;
  height: 6.5rem;
`;

const selector = (state: BoatStore): SubSumSelector => [
  state.nationality,
  state.id,
  state.type,
];

const SubSummary = () => {
  const [nationality, id, type] = useBoatStore(selector);

  return (
    <StyledSub className="bg-blue-opaq box right-side">
      {nationality !== null && type !== null ? (
        <>
          <Nationality nationality={nationality} />
          <ShipType type={type} />
        </>
      ) : null}
    </StyledSub>
  );
};

export default SubSummary;
