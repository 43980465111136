import React from "react";
import Construction from "./Construction";
import Links from "./Links";
import Tabs from "./Tabs";
import useTabState from "./useTabState";
import { css } from "@emotion/css";
const ConstructionCss = css`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  // height: inherit;
  // height: 100%;
  width: inherit;
`;

const ChildCss = css`
  flex-grow: 1;
  flex-shrink: 1;
  border-radius: 0px 1.5rem 8px 8px !important;
  padding-top: 0.75rem;
  overflow: hidden;
  max-width: 100%;

`;

const Main = () => {
  const current = useTabState((state) => state.current);
  return (
    <div className={ConstructionCss}>
      <Tabs />
      <div className={`box bg-blue-opaq ${ChildCss}`}>
        {/* <div className={ChildCss}> */}
        {current === "Links" ? <Links /> : <Construction />}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Main;
