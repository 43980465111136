import { Ship } from "src/types";
const addRetrofitKey = (s: any, i: number, a: any[]) => {
  const id = Number.parseInt(s.id);
  if (id > 2999 && id < 10000) {
    const basicShip = a.findIndex(
      (_s) => _s.name === s.name && _s.rarity !== s.rarity
    );
    Object.defineProperty(a[basicShip], "hasRetrofit", {
      value: true,
      configurable: true,
      enumerable: true,
      writable: true,
    });
  }
  return s;
};

const filterRetrofits = (ship: Exclude<Ship.Summary, "hasRetrofit">) => {
  const id = Number.parseInt(ship.id);
  if (id > 2999 && id < 10000) {
    // const basicShip = arr.findIndex(
    //   (_s) => _s.name === ship.name && _s.rarity !== ship.rarity
    // );
    // Object.defineProperty(arr[basicShip], "hasRetrofit", {
    //   value: true,
    //   configurable: true,
    // });
    return false;
  } else {
    return true;
  }
  //@ts-ignore
};
export { addRetrofitKey, filterRetrofits };
export default filterRetrofits;
