import storage from "./storage";
import {
  getDownloadURL,
  ref,
  listAll,
  StorageReference,
} from "firebase/storage";
import { Ship } from "src/types";

export type PickedType = {
  id: string;
  skins: Ship.SkinInfo[];
};
type ReffedSkin = Ship.SkinInfo & { ref: StorageReference };

function notUndefined(item: unknown): item is ReffedSkin {
  return !!item;
}

const splitName = (path: string) => path.split("/").slice(-2).reverse().pop()!;

/**
 * Skins from Firebase have extra skins ?? filter out
 */
const getSkinRefs = async ({
  id,
  skins,
}: PickedType): Promise<ReffedSkin[]> => {
  const { prefixes } = await listAll(ref(storage, `skins/${id}`));
  // Get skin_names from api data
  // Get contents of folder at ^
  // Filter out files in ^ - get folder names (skin names)
  const safeSkinNames = skins.map((skn) => splitName(skn.image));
  // ^ Get image name WITH underscors to match API data
  const refsIWant = prefixes
    .map((p) => p.name)
    .map((skn, index) => {
      const safeIndex = safeSkinNames.indexOf(skn);
      if (safeIndex === -1) {
        return undefined;
      }
      // ref @ this index is valid skin
      return {
        ref: prefixes[index],
        ...skins[safeIndex],
      } as unknown;
    }); // Remove undefined - got to!
  return refsIWant.filter(notUndefined);
};

const listSkinDir = async (props: PickedType) => {
  const refsIWant = await getSkinRefs(props);
  const fileRefs = await Promise.all(refsIWant.map((p) => listAll(p.ref)));
  const imageRefs = fileRefs.flatMap((f) =>
    f.items.filter((i) => {
      return i.name === "image.webp";
    })
  );
    console.log(imageRefs)
  const dlUrls = await Promise.all(imageRefs.map(getDownloadURL));
  const fff = dlUrls.map((url, index) => ({
    url,
    ...refsIWant[index],
  }));

  return fff;
};

export { listSkinDir };
