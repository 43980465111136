import { selectShip } from "src/hooks/useDb";
const selectRandom = async (): Promise<any> => {
  const id = Math.floor(Math.random() * (500 - 0 + 1))
    .toString()
    .padStart(3, "0");
  console.log(id);
  const [boat] = await selectShip({ id });
  if (boat === undefined) {
    return selectRandom();
  }
  return boat;
};

export default selectRandom;
