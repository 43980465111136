import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallow } from "src/hooks/useBoatContext";
import useFilter, { FilterSlice } from "src/hooks/useFilter";
import { debounce } from "lodash";
import "./input.scss";

const selector = (state: FilterSlice): [string, Function] => [
  state.searchString,
  state.setSearchString,
];
const fillInNameOnInit = () => {
  return new URLSearchParams(window.location.search).get("ship") ?? "";
};

const Input = React.memo(() => {
  const [searchString, setSearchString] = useFilter(selector, shallow);
  const [value, setValue] = useState(fillInNameOnInit());

  const handleInput = React.useCallback(
    (e: any) => {
      if (e.target.value === "") {
        setValue("");
        debounceSave("");
        return;
      }
      setValue(e.target.value);
      debounceSave(e.target.value);
    },
    [setSearchString]
  );
  const debounceSave = useRef(
    debounce((nextValue) => setSearchString(nextValue), 116)
  ).current;
  useEffect(() => {
    setValue(searchString);
  }, [searchString]);
  return (
    <input
      autoFocus
      placeholder="Search"
      className="my-input"
      type="search"
      value={value}
      onChange={handleInput}
    />
  );
});

export default Input;
