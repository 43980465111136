import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyAcuxieTfceASVzmQbRiY3eUSY_VoZ2oYQ",
  authDomain: "boats-1459b.firebaseapp.com",
  databaseURL: "https://boats-1459b.firebaseio.com",
  projectId: "boats-1459b",
  storageBucket: "boats-1459b.appspot.com",
  messagingSenderId: "919766216870",
  appId: "1:919766216870:web:2339a4a00fb51297ac4467",
  measurementId: "G-EKKJ9QQ6EY",
};

export const app = initializeApp(firebaseConfig);
