import styled from "@emotion/styled";
import { ReactComponent as PaintBrush } from "./assets/paint-brush.svg";
import { ReactComponent as Hammer } from "./assets/hammer.svg";
import useTabState from "./useTabState";

const Tabs = styled.div`
  -webkit-overflow-scrolling: touch;
  align-items: center;
  // align-content: center;
  display: flex;
  font-size: 1rem;
  // justify-content: space-between;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;

  & ul {
    display: contents;
  }

`;
const TabItem = styled.li`
  height: 3rem;

  background: ${({ selected }: { selected: boolean }) =>
    selected ? `var(--surface-1)` : `var(--surface-2)`};
  display: inline-flex;
  flex-grow: 0.3;
  align-items: center;
  justify-content: center;
  border-bottom: none;
  bottom: -2px;
  position: relative;
  list-style: none;
  cursor: pointer;
  &:first-child {
    border-top-left-radius: 0.75rem;
  }
  &:last-child {
    border-top-right-radius: 0.75rem;
  }
  & svg {
    height: 2rem;
    width: 2rem;
  }
`;
const Links = () => {
  const [current, setCurrent] = useTabState((state) => [
    state.current,
    state.setCurrent,
  ]);

  return (
    <Tabs className="is-boxed is-centered">
      <ul>
        <TabItem
          onClick={() => setCurrent("Construction")}
          selected={current === "Construction"}>
          <span>
            <Hammer />
          </span>
        </TabItem>
        <TabItem
          onClick={() => setCurrent("Links")}
          selected={current === "Links"}>
          <span>
            <PaintBrush />
          </span>
        </TabItem>
      </ul>
    </Tabs>
  );
};

export default Links;
