import React from "react";
import ResizedText from "src/components/common/ResizedText";
import SubImageDiv from "./SubSumImage";
import { abbreviations, Ship } from "src/types";
import { ShiptypeUrls } from "src/hooks/useImage/urlMaps";

const ShipType = ({ type }: { type: Ship.Type }) => {
  return (
    <div
      style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
      <div>
        <SubImageDiv
          type={"type"}
          src={ShiptypeUrls.get(abbreviations[type])}
        />
      </div>
      <ResizedText>{type}</ResizedText>
    </div>
  );
};

export default ShipType;
