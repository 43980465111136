import { Ship } from "src/types";
import * as d3 from "d3-color";

const priority = [
  // "hsl(151,59%,76%)",
  "rgb(182, 230, 158)", //"hsl(99.571,59%,76%)",
  "rgb(230, 158, 163)", // "hsl(356.714,59%,76%)",
  "rgb(158, 203, 230)", //"hsl(202.429,59%,76%)",
  // "hsl(253.857,59%,76%)",
  // "hsl(305.286,59%,76%)",
  // "hsl(48.143,59%,76%)",
];
// background:linear-gradient(120deg, #de8c8c 0%, #daa4a4 100%)
// 'background-image: linear-gradient( 135deg, #FDEB71 10%, #F8D800 100%);';
// const superRareColor = "#F2C46D";
// const eliteColor = "#58238C";
const superRareColor = d3.lab(82, 11, 75);
const eliteColor = d3.lab(55, 45, -48);
const rareColor = d3.lab(55, 10.93, -75);

const makeColor = (col: d3.LabColor) => {
  // const theColor = color(col)!.rgb();
  return [0.8, 0.6, 0.2].map((op, ind) => {
    const aColor = col.copy({
      opacity: op,
      l: col.l - ind * 3.14,
      a: col.a + ind * 6.28,
    });

    // aColor.opacity = op;
    // aColor.darker(ind + ind * 2);
    return aColor.formatRgb();
  });
};
const elite = makeColor(eliteColor);
const superRare = makeColor(superRareColor);
const rare = makeColor(rareColor); // [
// // "rgba(173, 146, 230, 0.8)",
// "rgba(164, 138, 222, 0.8)",
// "rgba(140, 117, 197, 0.6)",
// // "rgba(117, 96, 247, 0.6)",
// "rgba(95, 43, 69, 0.2)",
//];
// const rare = [
//   "rgba(120, 171, 222, 0.8)",
//   "rgba(116,156, 205, 0.6)",
//   "rgba(82, 124, 164, 0.2)",
// ];
const common = [
  "rgba(250, 250, 250, 0.8)",
  "rgba(200, 200, 200, 0.6)",
  "rgba(34, 43, 69, 0)",
];
export const RarityColors: Record<string, any> = {
  "Ultra Rare": priority,
  Decisive: priority,
  Priority: priority,
  "Super Rare": superRare,
  Rare: rare,
  Elite: elite,
  Common: common,
};
export const updateRarity = (currRarity: Ship.Rarity | null) => {
  switch (currRarity) {
    case null:
      return null;
    case "Common":
    case "Normal":
      return "Rare";
    case "Rare":
      return "Elite";
    case "Elite":
      return "Super Rare";
    default:
      return "Ultra Rare";
  }
};
export const rarityCalc = (rarity: Ship.Rarity | null): string[] => {
  switch (rarity) {
    case null:
      return ["rgba(8, 10, 16, 1)", "rgba(8, 10, 16, 1)", "rgba(8, 10, 16, 1)"];
    case "Super Rare":
      return RarityColors[rarity];
    case "Elite":
      return RarityColors[rarity];
    case "Rare":
      return RarityColors[rarity];
    case "Priority":
    case "Decisive":
    case "Ultra Rare":
      return RarityColors["Priority"];
    default:
      return RarityColors.Common;
  }
};
export const thecolor = (rarity: Ship.Rarity | null) =>
  rarityCalc(rarity).join(",");
