import styled from "@emotion/styled";
import { DEVICE_SIZE_STYLES } from "src/constants";
import useSkinViewer from "src/hooks/useSkinViewer";

const StyledContainer = styled.div`
  object-fit: contain;
  z-index: 1;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-size: contain;
  height: 16rem;
  min-width: 10rem;
  @media ${DEVICE_SIZE_STYLES.tablet} {
    height: 18.75rem;
  }
`;

function ImgBgComponent({ children }: any) {
  const { setSkinViewerOpen } = useSkinViewer();
  return (
    <StyledContainer onClick={() => setSkinViewerOpen(true)}>
      {children}
    </StyledContainer>
  );
}

export default ImgBgComponent;
