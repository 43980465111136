import { IMG_CATEGORY } from "src/types";
import db from "./db";
const detDb = (imageCategory: IMG_CATEGORY) => {
  switch (imageCategory) {
    case IMG_CATEGORY.shipyard:
      return db.shipyard;
    case IMG_CATEGORY.banners:
      return db.banners;
    // no default on purpose
  }
  console.info("db not specified in detDb");
  return db.banners;
};

export { detDb };
