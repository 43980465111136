import React, { useEffect, useRef, useState } from "react";
import useBoatStore from "src/hooks/useBoatContext";
import styled from "@emotion/styled";
import { RarityElement } from "./Rarity";
import { animated } from "react-spring";
const selector = (state: any) => [
  state.name,
  state.retrofitSelected && state.hasRetrofit,
];

const StyledShipName = styled.h1<{ small: boolean }>`
  ${(props: any) => (props.small ? "font-size: 1.75rem;" : "font-size: 2rem;")}
  padding-left: 0.2rem; // shadow
  // line-height: 1.5;
  font-weight: 600;
`;

const StyledRetrofit = styled.h1`
  font-size: 1.5rem;
  padding-left: 0.5rem;
`;
const RetrofitLabel = () => (
  <StyledRetrofit className="subtitle text-yellow">(Retrofit)</StyledRetrofit>
);
const ShipName = () => {
  const [small, setSmall] = useState(false);
  const [shipName, retrofitSelected] = useBoatStore(selector);
  const nameRef = useRef(null);

  useEffect(() => {
    if (nameRef.current === null) {
      return;
    }
    //@ts-ignore
    const width = nameRef.current.getBoundingClientRect().width;
    if (width > 300) {
      setSmall(true);
      return;
    }
    setSmall(false);
  }, [shipName]);

  return (
    <div
      className="shipname-item-height"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}>
      {shipName === null ? (
        <h1 className="title has-text-light">No Boat Selected</h1>
      ) : (
        <StyledShipName
          small={small}
          ref={nameRef}
          className="boat-name text-shadow ">
          {shipName}
        </StyledShipName>
      )}
      {shipName && retrofitSelected && <RetrofitLabel />}
    </div>
  );
};
const Container = () => {
  return (
    <>
      <ShipName />
      <RarityElement />
    </>
  );
};
export default Container;
