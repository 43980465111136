import React, { useState, useEffect } from "react";
import {
  useSpring,
  animated as a,
  config,
  useTrail,
  AnimatedProps,
} from "react-spring";
import { Link } from "react-router-dom";
type NavbarItemProps = {
  style: AnimatedProps<any>;
  to: string;
  label: string;
};
type BurgerProps = {
  onClick: any;
  isShowing: boolean;
};
const trans = (x: any, y: any) => `translateX(${x}px) translateY(${y}px)`;
const links = [
  {
    to: "/acknowledgements",
    label: "Acknowledgements",
  },
  {
    to: "/privacy",
    label: "Privacy Policy",
  },
  {
    to: "/contact",
    label: "Contact",
  },
];
const Burger = ({ onClick, isShowing }: BurgerProps) => (
  <span
    onClick={onClick}
    role="button"
    className={`navbar-burger burger ${isShowing ? "is-active" : ""}`}
    aria-label="menu"
    aria-expanded="false"
    data-target="navbarBasicExample">
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </span>
);
const NavbarItem = ({ style, to, label }: NavbarItemProps) => (
  <a.div style={{ ...style }} className={"navbar-item"}>
    <Link to={to}>{label}</Link>
  </a.div>
);
const HeaderComponent = () => {
  const [isShowing, setIsShowing] = useState(
    window.innerWidth >= 1024 ? true : false
  );

  const [trail, set] = useTrail(links.length, () => ({
    opacity: 0,
    xy: [-10, -5],
    config: config.stiff,
  }));
  const toggleMenu = () => {
    set({ opacity: isShowing ? 0 : 1, xy: isShowing ? [-10, -5] : [0, 0] });
    setIsShowing(!isShowing);
  };
  const anim = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    color: "#dbc599",
  });
  useEffect(() => {
    if (isShowing === true) {
      set.start({ opacity: 1, xy: [0, 0] });
    }
  }, [isShowing, set]);
  return (
    <nav className={"navbar"} role="navigation" aria-label="main navigation">
      <div className={"navbar-brand"}>
        <Link className={"navbar-item"} to="/">
          <a.h3
            className={"title has-text-primary"}
            color="#dbc599"
            style={anim}>
            Weaboats
          </a.h3>
        </Link>
        <Burger onClick={toggleMenu} isShowing={isShowing} />
      </div>
      <div className={`navbar-menu ${isShowing ? "is-active" : ""}`}>
        <div className={"navbar-start"}>
          {trail.map(({ opacity, xy }, index) => (
            <NavbarItem
              key={links[index].label}
              style={{ opacity, transform: xy.to(trans) }}
              to={links[index].to}
              label={links[index].label}
            />
          ))}
        </div>
      </div>
    </nav>
  );
};
export default HeaderComponent;
