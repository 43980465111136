import { IGetImage, IMG_CATEGORY } from "src/types";
import { detDb } from "./detDb";
const getImgFromDb = async (props: IGetImage): Promise<Blob | undefined> => {
  let dbRef = detDb(props.imageCategory);
  let image;
  try {
    switch (props.imageCategory) {
      case IMG_CATEGORY.shipyard:
        image = await dbRef.get({ id: props.id });
        break;
      case IMG_CATEGORY.banners:
        image = await dbRef.get({ id: props.id });
        break;
      // no default on purpose
    }
  } catch (err) {
    console.error(err);
    throw new Error("Error reading image from DB");
  }
  if (image && image.data) {
    return image.data;
  }
  return undefined; // if no img found for shipyard
};
export { getImgFromDb };
