import type * as APIShip from "./hooks/shiptype";

export enum IMG_CATEGORY {
  banners = "banners",
  icons = "icons",
  misc = "misc",
  shiptypes = "shiptypes",
  skills = "skills",
  shipyard = "shipyard",
}

export type ParsedName = string;
export function parseName(name: string): ParsedName {
  return name.replace(/ /g, "_");
}

export type IGetImage = {
  imageCategory: IMG_CATEGORY;
  id: string;
  shipName?: string;
};

export type IShipBanner = {
  id: string;
  data: Blob;
};

export type IShipyardImage = {
  id: string;
  data: Blob;
};

export interface SearchableShip extends Ship.Summary {
  searchName: string[];
}
export type BoatStore = {
  wikiUrl: APIShip.Url | null;
  id: APIShip.ShipID | null;
  name: string | null;
  type: Ship.Type | null;
  nationality: string | null;
  rarity: Ship.Rarity | null;
  skills: Ship.Skill[] | null;
  hasRetrofit: boolean | null;
  stars: number | null;
  misc: {
    artist?: APIShip.Artist;
    voice?: APIShip.VA;
  } | null;
  hexagon: [number, number, number, number, number, number] | null;
  stats: APIShip.ShipStats | null;
  skins: APIShip.Skin[] | null;
  selectBoat: (boat: Ship.Summary) => void;
  // getDetail: () => Promise<void>;
  construction: null | {
    constructionTime: string;
    availableIn: {
      light: boolean;
      heavy: boolean;
      aviation: boolean;
      limited: boolean;
      exchange: boolean;
    };
  };
  obtainedFrom: {
    obtainedFrom?: string; // source, etc "Available in Medal Exchange for \"Medal\" 80."
    fromMaps: string[]; // map ids, etc "1-1" "10-2"
  } | null;
};
export namespace Ship {
  export type Type = ShipType;
  type Stats = Record<string, any>;
  export type Nationality = typeof nationalities[number];
  // export type Skins = {
  //   background: string;
  //   chibi: string;
  //   image: string;
  //   info: {
  //     live2dModel: boolean;
  //     obtainedFrom: string;
  //   };
  //   name: string;
  // }[];
  export type SkinInfo = APIShip.Skin;
  export type Skins = APIShip.Skin[];
  export type Rarity =
    | "Elite"
    | "Rare"
    | "Super Rare"
    | "Common"
    | "Normal"
    | "Ultra Rare"
    | "Priority"
    | "Decisive";
  export type Summary = {
    id: string;
    name: string;
    rarity: Rarity;
    type: Ship.Type;
    nationality: string;
    hasRetrofit: boolean | null;
  };
  export type Skill = {
    color: string;
    description: string;
    icon: string;
    names: {
      en: string;
    };
  };

  export type ShipStats = {
    baseStats: Stats;
    level100: Stats;
    level120: Stats;
    level125: Stats;
    level100Retrofit?: Stats;
    level120Retrofit?: Stats;
    level125Retrofit?: Stats;
  };
  export type Links = {
    wikiUrl: string;
    artist: { name: string };
    voice: { name: string; url: string };
  };
  export type Misc = {
    artist?: APIShip.Artist;
    voice?: APIShip.VA;
  };
}
export const nationalities = [
  "Universal",
  "Eagle Union",
  "Royal Navy",
  "Sakura Empire",
  "Iron Blood",
  "Dragon Empery",
  "Sardegna Empire",
  "Northern Parliament",
  "Iris Libre",
  "Vichya Dominion",
  "META",
  "Universal",
  "Neptunia",
  "Bilibili",
  "Utawarerumono",
  "Kizuna AI",
  "Hololive",
  "Venus Vacation",
] as const;
type ShipType =
  | "Destroyer"
  | "Light Cruiser"
  | "Large Cruiser"
  | "Heavy Cruiser"
  | "Monitor"
  | "Battleship"
  | "Battlecruiser"
  | "Aviation Battleship"
  | "Aircraft Carrier"
  | "Light Aircraft Carrier"
  | "Submarine Carrier"
  | "Submarine"
  | "Repair Ship";
export const abbreviations: Record<ShipType, string> = {
  Destroyer: "DD",
  "Light Cruiser": "CL",
  "Heavy Cruiser": "CA",
  "Large Cruiser": "CB",
  Monitor: "BM",
  Battleship: "BB",
  Battlecruiser: "BC",
  "Aviation Battleship": "BBV",
  "Aircraft Carrier": "CV",
  "Light Aircraft Carrier": "CVL",
  Submarine: "SS",
  "Submarine Carrier": "SSV",
  "Repair Ship": "AR",
};
