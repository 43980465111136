import type { Ship } from "src/types";
export interface TableProps extends Required<Ship.Misc> {
  wikiUrl: string;
}
const Table = ({ artist, voice, wikiUrl }: TableProps) => {
  const artLinks = Object.keys(artist.urls);
  return (
    <table className="table is-fullwidth is-striped">
      <thead>
        <tr>
          <th>Illustrator</th>
          <th>Voice Actor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{artist?.name}</td>
          <td>{voice?.name}</td>
        </tr>
        <tr>
          <td style={{ maxWidth: "100px", background: "var(--surface-1)"}}>
            <div >
              {artLinks.map((k) => (
                <a href={artist.urls[k]}>
                  {k}
                  <br />
                </a>
              ))}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { Table };
