import React, { useEffect, useState, Suspense, lazy } from "react";
import Search from "./Search";
import Detail from "./Detail";
import styled from "@emotion/styled";
import { DEVICE_SIZE_STYLES } from "src/constants";
import ButtonsRow from "./ButtonsRow";
import { getShiptypeImages } from "src/hooks/useImage";
import Loader from "./Search/AllShips/Loader";
const SkinViewer = lazy(() => import("./SkinViewer"));
const Filter = lazy(() => import("./Filter"));

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto fit-content(720px);
  grid-template-areas: "search detail";
  @media ${DEVICE_SIZE_STYLES.mobileL} {
    grid-template-columns: 0.8fr 1fr;
    content: "laptop";
  }
`;

const StyledSearch = styled(Search)`
  grid-areas: search;
`;
const StyledDetail = styled(Detail)`
  min-width: 500px;
  grid-areas: detail;
`;
const StyledButtonRow = styled(ButtonsRow)`
  grid-areas: bb;
`;
const MainApp = () => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    getShiptypeImages().then(() => setLoaded(true));
  }, []);
  if (loaded === false) {
    return null;
  }
  return (
    <div className="container is-fullhd">
      <StyledButtonRow />
      <Grid>
        <StyledSearch />
        <StyledDetail />
      </Grid>
      <Suspense fallback={<Loader />}>
        <Filter />
        <SkinViewer />
      </Suspense>
    </div>
  );
};

export default MainApp;
