import styled from "@emotion/styled";
import { DEVICE_SIZE_STYLES } from "src/constants";
type SubSumImageProps = {
  type: "nationality" | "type";
  src: string;
};
const SubSumImage = styled.img`
  // min-height: 2rem;
  // min-width: 2.5rem;
  height: auto;
  ${(props: SubSumImageProps) =>
    props.type === "nationality"
      ? `
      min-height: 2.75rem;
      width: 2.75rem;
      margin-left: -0.25rem;
      `
      : `
      width: 2rem;
      margin-top: 0.25rem;
      `}
`;
const SubImageDiv = ({ type, ...props }: SubSumImageProps) => (
  <div style={{ minWidth: "2.75rem", minHeight: "2rem" }}>
    <SubSumImage type={type} {...props} />
  </div>
);

export default SubImageDiv;
