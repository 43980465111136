import { IGetImage } from "src/types";
import db from "./db";
import { detDb } from "./detDb";
export { storeBanner } from "./storeBanner";
export { getImgFromDb } from "./getImgFromDb";
export { shipTrie } from "./shipTrie";
// Look up forwardingRef...make faster.
// export const useNameSearch = () => {
//   const search = useCallback(
//     (s: string) =>
//       db.shipList
//         .where("name")
//         .startsWithIgnoreCase(s)
//         .limit(8)
//         .distinct()
//         .toArray(),
//     []
//   );
//   return search;
// };
const clearDb = () => {
  localStorage.clear();
  db.delete().then(() => {
    window.location.reload();
  });
};

const selectShip = ({ id }: { id: string }) =>
  db.shipList.where({ id }).toArray();

const storeImg = async (props: IGetImage, b: Blob) => {
  let dbRef = detDb(props.imageCategory);
  const exists = await dbRef.get({ id: props.id });
  if (exists) {
    return dbRef
      .where("id")
      .equals(props.id.toString())
      .modify({ data: b })
      .catch((err) => {
        console.error(err);
        throw new Error("Error storing shipyard image");
      });
  }
  // console.log(props)
  return dbRef.put({ id: props.id.toString(), data: b });
};

export { clearDb, selectShip, storeImg };
