//@ts-nocheck
import { css } from "@emotion/css";
import React from "react";
import useBoatStore, { BoatStore, shallow } from "src/hooks/useBoatContext";
import { determineIfAvailable } from "./determineIfAvailable";
import { ReactComponent as Map } from "../assets/map-marked-alt.svg";

const selector = (state: BoatStore) => ({
  construction: state.construction,
  obtainedFrom: state.obtainedFrom,
  rarity: state.rarity,
});

const AvailabilityContainer = css`
  padding-right: 1.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  width: calc(100% + 40px);
`;

const Construction = React.memo(() => {
  const { construction, obtainedFrom, rarity } = useBoatStore(
    selector,
    shallow
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div style={{ height: "4rem" }}>
        <h1 className="title is-5">Construction</h1>
        <p className="subtitle is-6 body-text">
          {obtainedFrom
            ? determineIfAvailable(construction, obtainedFrom, rarity)
            : "-"}
        </p>
      </div>
      {/* &nbsp; */}
      {/* <CategoryElement categories={construction?.availableIn} /> */}
      {/*Kirishima has a long one */}
      <div className={AvailabilityContainer}>
        <h1 className="title is-5">Availability</h1>
        <p className="subtitle is-6 has-text-right body-text">
          <div style={{ overflow: "auto", height: "4.8rem" }}>
            {obtainedFrom?.obtainedFrom ?? " "}
          </div>
        </p>
      </div>
      <div>
        <button
          className={`button is-text is-large`}
          onClick={() => alert("This don't do nothing yet. Baby steps!")}>
          <span className="icon is-large">
            <Map className="svg-style fa fas" />
          </span>
        </button>
      </div>
    </div>
  );
});
export default Construction;
