import React, { useEffect, useMemo } from "react";
import { useTransition, animated as a, config, useSprings } from "react-spring";
import { useGesture } from "@use-gesture/react";
import styled from "@emotion/styled";
import { SearchSlice } from "src/hooks/useBoatContext";
import { COLORS, DEVICE_SIZE_STYLES, sizes } from "src/constants";
import SearchResult from "./SearchResult";
import { BoatStore } from "src/types";
import useResults from "src/hooks/useResults";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  // width: 25rem;
  height: 100%;
  @media ${DEVICE_SIZE_STYLES.mobileS} {
    width: 17.5rem;
  }
  @media ${DEVICE_SIZE_STYLES.mobileL} {
    width: 17.5rem;
  }
  @media ${DEVICE_SIZE_STYLES.laptop} {
    width: 27rem;
  }
`;
const StyledSearchBody = styled.div`
    padding: 1rem 0.5rem;
    // display: flex;
    border-radius: 14px;
    background-color: ${COLORS.DARK_BLUE};
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    @media ${DEVICE_SIZE_STYLES.laptop} {
      padding: 1rem;
      border-radius: 24px;
    }
  }  
`;
const SEARCH_RESULT_SPACING =
  window.innerWidth < parseInt(sizes.tablet) ? 75 : 100;
const animConfigs = {
  hover: { scale: 1.08, shadow: 15 },
  initial: { scale: 1, shadow: 1 },
  click: {
    scale: 1.01,
  },
};

const to = (active: boolean) =>
  active ? animConfigs.hover : animConfigs.initial;
// const clickTo = (down: boolean) => down ? {scale}

const SearchBody = () => {
  const [results, selectBoat] = useResults();

  const [springs, api] = useSprings(
    results.length,
    () => ({ ...animConfigs.initial, config: { friction: 50, tension: 500 } }),
    [results]
  );

  const bind = useGesture({
    onAuxClick: ({ args: [index] }) => handleMiddleClick(index),
    onClick: ({ args: [index] }) => {
      handleClick(index);
    },
    onDrag: ({ down, tap, args: [index] }) => {
      api.start((i) => {
        if (index !== i) return;
        return down ? animConfigs.click : animConfigs.hover;
      });
    },
    onHover: ({ active, args: [index] }) => {
      api.start((i) => {
        if (index !== i) return;
        return to(active);
      });
    },
  });
  const handleClick = (index: number) => {
    selectBoat(results[index]);
  };
  const handleMiddleClick = (index: number) => {
    window.open(`/?ship=${results[index].name}`, "_blank");
  };
  const transFunction = useMemo(
    () =>
      results.map((items, ind) => ({
        ...items,
        y: ind * SEARCH_RESULT_SPACING,
      })),
    [results]
  );
  const transitions = useTransition(transFunction, {
    key: (item: any) => item.name,
    from: { height: 0, opacity: 0, x: 21 },
    leave: { height: 0, opacity: 0, x: 13 },
    enter: ({ y }) => ({ y, x: 0, opacity: 1 }),
    update: ({ y }) => ({ y }),
    config: { frequency: 0.21, damping: 1.2 },
    trail: 21,
  });
  useEffect(() => {
    if (results.length === 1) {
      selectBoat(results[0]);
    }
  }, [results]);

  return (
    <StyledSearchBody>
      <ul>
        {transitions((styles, item, t, index) => (
          <a.li
            className="my-dib"
            style={{
              // zIndex: results.length - index,
              scale: springs[index]?.scale,
              boxShadow: springs[index]?.shadow.to(
                (s) => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`
              ),
              ...styles,
            }}>
            <span style={{ touchAction: "none" }} {...bind(index)}>
              <SearchResult {...item} key={item.name} />
            </span>
          </a.li>
        ))}
      </ul>
    </StyledSearchBody>
  );
};

const SearchMain = () => {
  return (
    <Container>
      <SearchBody />
    </Container>
  );
};
export default SearchMain;
