import create, { GetState, SetState } from "zustand";
type TabContent = "Construction" | "Links";
type StoreState = {
  current: TabContent;
  setCurrent: (t: TabContent) => void;
};
type StoreSlice<T> = (
  set: SetState<StoreState>,
  get: GetState<StoreState>
) => T;

const useTabState = create<StoreState>((set, get) => ({
  current: "Construction",
  setCurrent: (c: TabContent) => set({ current: c }),
}));

export default useTabState;
