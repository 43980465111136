import shallow from "zustand/shallow";
import { Ship } from "src/types";

import useBoatStore, { BoatStore, SearchSlice } from "../useBoatContext";
import useFilter from "../useFilter";
import { useEffect } from "react";
// let targets = fuzzysort.prepare(ship)
import determineResults from "./determineResults";
import { useSearchParams } from "react-router-dom";
const boatStoreSelector = (
  state: SearchSlice & BoatStore
): [Ship.Summary[], Function, Function] => [
  state.results,
  state.setResults,
  state.selectBoat,
];

const useResults = (): [any[], Function] => {
  const [searchParams] = useSearchParams();
  const { nationality, type, searchString, setSearchString } = useFilter();
  const [results, setResults, selectBoat] = useBoatStore(
    boatStoreSelector,
    shallow
  );

  useEffect(() => {
    const r = determineResults({ nationality, type, searchString });
    setResults(searchString === "" ? r : r.slice(0, 9));
  }, [nationality, type, searchString]);
  useEffect(() => {
    if (results.length === 1) {
      selectBoat(results[0]);
    }
  }, [results]);
  useEffect(() => {
    const ship = searchParams.get("ship");
    if (ship) {
      setSearchString(ship);
    }
  }, [searchParams]);
  useEffect(() => {
    if (nationality !== null && type !== null) {
      setSearchString("");
    }
  }, [nationality, type]);

  return [results, selectBoat];
};

export default useResults;
