import React from "react";
import styled from '@emotion/styled'
import type { Ship } from 'src/types'
import { rarityCalc } from "src/components/MainApp/RarityBackground";
const Image = styled.img`
    position: absolute;
    // top: 0;
    z-index: 0;
`
type RowContainerProp = {
    rarity: Ship.Rarity;
}
const LinearGradient = styled.div`
background-image: linear-gradient(to right bottom, ${(props: RowContainerProp) => rarityCalc(props.rarity).join(',')});
background-size: cover;
`
const RowContainer = styled(LinearGradient)`
    will-change: transform;
    overflow: hidden;
    transform-style: flat;
   
    display: flex;
    position: relative;
    // margin: 5px 7px;
    height: 80px;
    width: 420px;
    // justify-content: space-between;
    align-items: center;
    border-radius: 18px;
    box-sizing: border-box;
`

export { Image, RowContainer, LinearGradient }