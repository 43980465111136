// import shipTypesJson from "src/assets/shipTypes.json";
import { nationalities } from "src/types";
import { ShiptypeUrls, NationUrls } from "./urlMaps";
const abbreviations: Record<string, string> = {
  Destroyer: "DD",
  "Light Cruiser": "CL",
  "Heavy Cruiser": "CA",
  "Large Cruiser": "CB",
  Monitor: "BM",
  Battleship: "BB",
  Battlecruiser: "BC",
  "Aviation Battleship": "BBV",
  "Aircraft Carrier": "CV",
  "Light Aircraft Carrier": "CVL",
  Submarine: "SS",
  "Submarine Carrier": "SSV",
  "Repair Ship": "AR",
};
const fetcher = (url: string) =>
  fetch(`/shiptypes/${url}_img40.png`).then((res) => res.blob());
const nationFetcher = (nation: string) => {
  nation = nation.replace(/ /g, "_");
  return fetch(`/nationalities/${nation}.png`)
    .then((res) => res.blob())
    .catch((err) => {
      console.error(err);
      return err;
    });
};
// Run this in some kinda setup?
const getShiptypeImages = async () => {
  if (ShiptypeUrls.size === 0) {
    const imgPrefixes = Object.values(abbreviations);
    const nationBlobs = await Promise.all(nationalities.map(nationFetcher));
    const blobs = await Promise.all(imgPrefixes.map(fetcher));
    // shiptype images are now in memory.
    blobs.forEach((b, index) => {
      ShiptypeUrls.set(imgPrefixes[index], URL.createObjectURL(b));
    });
    nationBlobs.forEach((b, index) => {
      NationUrls.set(nationalities[index], URL.createObjectURL(b));
    });
  }
};

export default getShiptypeImages;
