import { invert } from "lodash";
import fuzzysort from "fuzzysort";
import { abbreviations, Ship } from "src/types";
import { shipList } from "../useDb/db";

type DetermineResultsProps = {
  nationality: string | null;
  type: string | null;
  searchString: string;
};
const reverseAbbr = invert(abbreviations);
shipList.sort(function (a, b) {
  var nameA = a.name.toLowerCase(),
    nameB = b.name.toLowerCase();
  if (nameA < nameB)
    //sort string ascending
    return -1;
  if (nameA > nameB) return 1;
  return 0; //default return value (no sorting))
});
const atLeastTwoFilters = (nat: boolean, type: boolean, str: string) =>
  str.length > 0 ? nat || type : nat && type; // if no search return true if both nat and type
const determineResults = ({
  nationality,
  type,
  searchString,
}: DetermineResultsProps): Ship.Summary[] => {
  let results: Ship.Summary[] = shipList;
  const atleastTwo = atLeastTwoFilters(!!nationality, !!type, searchString);
  if (searchString.length === 0 && atleastTwo === false) {
    // No filters
    return []; // Deselect - no results
  }
  if (nationality) {
    results = results.filter((s) => s.nationality === nationality);
  }
  if (type) {
    results = results.filter((s) => s.type === reverseAbbr[type as string]);
  }

  if (searchString === "") {
    return results;
  }
  return fuzzysort
    .go(searchString, results, {
      key: "name",
      limit: 10,
      threshold: -1000,
      allowTypo: false,
    })
    .map((res) => res.obj);
};

export default determineResults;
