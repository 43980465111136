import { abbreviations, Ship } from "src/types";
import { ShiptypeUrls } from "src/hooks/useImage/urlMaps";
import React from "react";
const ShiptypeImg = React.memo(
  ({ type, style }: { type: Ship.Type; style?: any }) => {
    return (
      <img
        alt="shiptype"
        src={ShiptypeUrls.get(abbreviations[type])}
        datatype="image/webp"
        style={style}
        className="type-icon"
      />
    );
  }
);

export default ShiptypeImg;
