import Dexie from "dexie";
//@ts-ignore
import type {
  IShipyardImage,
  IShipBanner,
  Ship,
} from "src/types";
import ships_list from "../../assets/ship-list.json";
import { filterRetrofits, addRetrofitKey } from "./filterRetrofits";

// export { ships_list };
//@ts-ignore
export const shipList = Object.values(ships_list)
  .map(addRetrofitKey)
  .filter(filterRetrofits);
class ShipsDatabase extends Dexie {
  //@ts-ignore
  shipList: Dexie.Table<Ship.Summary, number>;
  //@ts-ignore
  banners: Dexie.Table<IShipBanner, number>;
  //@ts-ignore
  shipyard: Dexie.Table<IShipyardImage, number>;
  constructor() {
    super("ShipsDatabase");
    this.version(1).stores({
      shipList: "id, name",
      banners: "id",
      shipyard: "id",
    });
  }
}

const db = new ShipsDatabase();
// db.shipList.hook("creating", (_, obj) => {
//   if (typeof obj.name === "string") {
//     obj.searchName = getAllWords(obj.name);
//   }
// });

db.on("populate", () => {
  //@ts-ignore
  db.shipList.bulkAdd(shipList);
});
export default db;
