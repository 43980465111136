const Tabs = () => {
  return null
//   return (
//     <div className="tabs is-toggle">
//   <ul>
//     <li className="is-active">
//       <a>
//         <span>Pictures</span>
//       </a>
//     </li>
//     <li>
//       <a>
//         <span>Music</span>
//       </a>
//     </li>
//     <li>
//       <a>
//         <span>Videos</span>
//       </a>
//     </li>
//     <li>
//       <a>
//         <span>Documents</span>
//       </a>
//     </li>
//   </ul>
// </div>
//   );
};
export default Tabs
