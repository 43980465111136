import React from "react";
import styled from "@emotion/styled";
import { clearDb } from "src/hooks/useDb";
import { ReactComponent as Trash } from "./trash-alt.svg";
import { ReactComponent as Dice } from "./dice.svg";
import { ReactComponent as Filter } from "./filter.svg";
import useBoatStore from "src/hooks/useBoatContext";
import selectRandom from "src/hooks/selectRandom";
import type { BoatStore } from "src/types";
import useFilter from "src/hooks/useFilter";
import Input from "./Input/Input";
const boatSelector = (state: BoatStore) => state.selectBoat;
const filterSelector = (state: any) => state.setOpen;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
`;
const ButtonsRowContainer = styled.div`
  padding: 0.5rem;
`;
const ButtonsRow = () => {
  const selectBoat = useBoatStore(boatSelector);
  const toggleOpen = useFilter(filterSelector);
  const deleteDb = () => {
    clearDb();
  };
  const doSelectRandom = () => {
    selectRandom().then((b) => {
      selectBoat(b);
    });
  };
  const doToggleOpen = () => {
    toggleOpen(true);
  };

  return (
    <Container>
      <Input />
      <ButtonsRowContainer>
        <button
          className="button is-light"
          aria-label="Filters"
          title="Filters"
          onClick={doToggleOpen}>
          <Filter height="24" />
        </button>
        <button
          className="button is-light"
          aria-label="Random Ship"
          title="Random Ship"
          onClick={doSelectRandom}>
          <Dice height="24" />
        </button>
        <button
          className="button is-light"
          aria-label="Clear Cache"
          title="Clear Cache"
          onClick={deleteDb}>
          <Trash height="24" />
        </button>
      </ButtonsRowContainer>
    </Container>
  );
};

export default ButtonsRow;
