import React, { CSSProperties } from "react";


const ResizedText = React.memo(({ children, style }: any) => {
  let textSize = "1.05rem";
  let textStyle: CSSProperties = {
    whiteSpace: "pre-wrap",
    marginTop: "-0.25rem"
  };
  let len = children.length;
  //   switch (children.length)
 if (len > 13 && len <= 16) {
    textSize = "0.9rem";
  } else if (len >= 17 && len <= 22) {
    textStyle.marginTop = "-0.2rem";
    textSize = "0.85rem";
  } else if (len > 22) {
    textStyle.marginTop = "-0.25rem";
    textSize = "0.8rem";
  }
  return (
    <p style={{ fontSize: textSize, ...style, ...textStyle, fontFamily: 'Inter' }}>{children}</p>
  );
});

export default ResizedText;
