import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useTransition, animated as a, config } from "react-spring";
import useBoatStore from "src/hooks/useBoatContext";
import { BoatStore } from "src/types";
import RarityText from "./RarityText";

import { ReactComponent as Star } from "./star.svg";
const StyledRarityElement = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
`;
const configur = (k: string) => {
  return k === "opacity" ? { clamp: true } : config.wobbly;
};
const AStar = a(Star);
const selector = (stats: BoatStore): [number, any] => [
  stats.stars ?? 0,
  stats.rarity,
];
const RarityElement = () => {
  const [stars, rarity] = useBoatStore(selector);
  const [trans, setTrans] = useState<any>([]);
  const transitions = useTransition(trans, {
    from: { opacity: 0, y: 3, x: 0 },
    enter: (item) => ({ x: item * 1.25, opacity: 1, y: 0 }),
    leave: {
      opacity: 0,
      y: 3,
      config: configur,
      trail: 0,
    },
    config: config.wobbly,
    trail: 30,
    // delay: 200,
    // onRest: () => setItems([]),
  });
  useEffect(() => {
    if (stars) {
      //  Array(stars).fill(<Star />, 0, stars)
      return setTrans(
        new Array(stars).fill((el: any) => ({ star: `${el}` })).map((s, i) => i)
      );
    }
    setTrans([]);
  }, [stars]);

  return (
    <div
      className="shipname-item-height"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        alignContent: "center",
      }}>
      <div>
        <p className="subtitle text-shadow">{rarity}</p>
      </div>
      <StyledRarityElement>
        {transitions((styles) => (
          <AStar style={styles} />
        ))}
      </StyledRarityElement>
    </div>
  );
};

export { RarityElement };
