import { Ship } from "src/types";
import styled from "@emotion/styled";
import React from "react";

type SkillIconsProps = {
  skills?: Ship.Skill[];
  currentSelected: number;
  onSelect: (i: number) => void;
};
type StyledSkillIconProps = {
  active: boolean;
};
const StyledSkillIcon = styled.img<StyledSkillIconProps>`
  height: 5rem;
  width: 5rem;
  padding: 0.5rem;

  border-radius: 0.75rem;
  cursor: pointer;
  ${(props) =>
    props.active &&
    `background-color: #485c8f;
    `}
`;

const SkillIcons = React.memo(
  ({ skills, currentSelected, onSelect }: SkillIconsProps) => (
    <div style={{ height: "5.55rem" }}>
      {skills &&
        skills.map((s) => (
          <StyledSkillIcon
            src={s.icon}
            key={s.icon}
            onClick={() => onSelect(skills.indexOf(s))}
            active={skills.indexOf(s) === currentSelected}
          />
        ))}
    </div>
  )
);

export default SkillIcons;
