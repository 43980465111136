import React, { useEffect, useRef } from "react";
import fetchBanner, { BannerUrls } from "src/hooks/useImage";
import styled from "@emotion/styled";
import { rarityCalc } from "../../RarityBackground";
import { DEVICE_SIZE_STYLES } from "src/constants";
import { IMG_CATEGORY, Ship } from "src/types";
import { ShiptypeUrls } from "src/hooks/useImage/urlMaps";
import ShiptypeImg from "src/components/common/ShiptypeImg";
type RowContainerProp = {
  rarity: Ship.Rarity;
};
const imgSizes = `
// @media ${DEVICE_SIZE_STYLES.mobileS} {
//   width: 17.5rem;
//   height: 3.9rem;
// }
@media ${DEVICE_SIZE_STYLES.mobileL} {
  width: 270px;
  height: 60px;
}
@media ${DEVICE_SIZE_STYLES.laptop} {
  width: 360px;
  height: 80px;
}
`;
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  right: 22px;
`;
const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  object-fit: contain;
  @media ${DEVICE_SIZE_STYLES.mobileL} {
    width: 270px;
    height: 60px;
  }
  @media ${DEVICE_SIZE_STYLES.laptop} {
    width: 360px;
    height: 80px;
  }
`;

const RowContainer = styled.div`
  cursor: pointer;
  overflow: hidden;
  transform-origin: bottom;
  transform-style: flat;
  background-image: linear-gradient(
    to right,
    ${(props: RowContainerProp) => rarityCalc(props.rarity).join(",")}
  );
  background-size: cover;
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 18px;
  box-sizing: border-box;
  @media ${DEVICE_SIZE_STYLES.mobileL} {
    width: 270px;
    height: 60px;
  }
  @media ${DEVICE_SIZE_STYLES.laptop} {
    width: 400px;
    height: 80px;
  }
`;
const abbreviations: Record<string, string> = {
  Destroyer: "DD",
  "Light Cruiser": "CL",
  "Heavy Cruiser": "CA",
  "Large Cruiser": "CB",
  Monitor: "BM",
  Battleship: "BB",
  Battlecruiser: "BC",
  "Aviation Battleship": "BBV",
  "Aircraft Carrier": "CV",
  "Light Aircraft Carrier": "CVL",
  Submarine: "SS",
  "Submarine Carrier": "SSV",
  "Repair Ship": "AR",
};

const SearchResult = React.memo(
  ({ name, rarity, id, nationality, type }: Ship.Summary) => {
    const ref = useRef(null);
    useEffect(() => {
      fetchBanner({ id, shipName: name, imageCategory: IMG_CATEGORY.banners })
        .then((d: Blob) => {
          if (BannerUrls.has(name)) {
            //@ts-ignore
            ref.current.src = BannerUrls.get(name);
            return;
          }
          const url = URL.createObjectURL(d);
          //@ts-ignore
          ref.current.src = url;
          BannerUrls.set(name, url);
        })
        .catch((e) => {
          console.error(e);
        });
    });

    return (
      <RowContainer rarity={rarity}>
        <NameContainer>
          <p className="boat-name m-right-10 text-shadow">{name}</p>
          <ShiptypeImg type={type} />
        </NameContainer>
        <Image ref={ref} datatype="image/webp" />
      </RowContainer>
    );
  }
);
export default SearchResult;
