import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderComponent from "./components/Header/Header";
import MainComponent from "./components/Main";
const App: React.FC = () => {
  return (
    <Router>
      <HeaderComponent/>

      <MainComponent />
    </Router>
  );
};

export default App;
