import React from "react";
import styled from "@emotion/styled";
import useBoatStore, { shallow, StoreState } from "src/hooks/useBoatContext";
const selector = (state: StoreState) => [
  state.hasRetrofit,
  () => {
    state.setRetrofit(!state.retrofitSelected);
  },
];
const StyledContainer = styled.div`
  //   background: var(--surface-1-opaque);
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  & h1:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
`;

const HasRetrofit = () => {
  const [hasRetrofit, toggleRetrofit] = useBoatStore<any>(selector, shallow);
  return (
    <StyledContainer className="right-side">
      {hasRetrofit && <h1 onClick={() => toggleRetrofit()}>Toggle Retrofit</h1>}
    </StyledContainer>
  );
};

export { HasRetrofit };

export default HasRetrofit;
